<template>
  <div class="bgm" style="overflow-y:scroll;overflow-x:hidden;height:100%">
    <!-- 顶部栏 -->
    <top-banner theme="white" @backHome="backHome"></top-banner>
    <!-- 标题 -->
    <title-enzh enTitle="Enterprise Deployment" zhTitle="企业部署" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
    <!-- 模型图 -->
    <div class="x_between">
        <div v-for="(item,index) in modelList" :key="index" class="model_box column_x_center">
            <img :src="item.modelImg" style="width: 94%;">
            <div class="describe" v-text="item.describe"></div>
        </div>
    </div>
    <!-- 底部图标 -->
    <div class="x_between">
        <div v-for="(item,index) in iconList" :key="index" class="icon_box column_xy_center">
            <img :src="item.iconImg" style="width: 60px;">
            <div class="title" v-text="item.title"></div>
        </div>
    </div>
  </div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
import titleEnzh from "@/components/titleEnzh.vue"
export default {
    components:{
        topBanner,
        titleEnzh
    },
    data(){
        return {
            modelList:[
                {
                    modelImg:require("../assets/image/enterpriseDeployment/model1.png"),
                    describe:"无需专业人员进行网络设置，并且管理员可远程对设备进行配置，无需到设备现场，真正实现远程运维。"
                },
                {
                    modelImg:require("../assets/image/enterpriseDeployment/model2.png"),
                    describe:"支持纯软件或软硬结合的组网方式，根据用户的使用场景，部署最符合企业需求的虚拟专用网络。"
                },
                {
                    modelImg:require("../assets/image/enterpriseDeployment/model3.png"),
                    describe:"支持防火墙、ACL、上网行为管理等安全防护功能。通过云平台进行远程按需部署，实现数据安全传输。"
                },
            ],
            iconList:[
                {
                    iconImg:require("../assets/image/enterpriseDeployment/icon1.svg"),
                    title:"7x24小时全天候服务中心"
                },
                {
                    iconImg:require("../assets/image/enterpriseDeployment/icon2.svg"),
                    title:"分钟级反馈式主动运维服务"
                },
                {
                    iconImg:require("../assets/image/enterpriseDeployment/icon3.svg"),
                    title:"云管端一站式实施服务"
                },
                {
                    iconImg:require("../assets/image/enterpriseDeployment/icon4.svg"),
                    title:"国际标准质量管理体系认证"
                },
                {
                    iconImg:require("../assets/image/enterpriseDeployment/icon5.svg"),
                    title:"无缝融合各主流厂商设备"
                },
            ]
        }
    },
    methods:{
        backHome(){
            this.$router.push("/")
        }
    },
}
</script>

<style lang="scss" scoped>
.bgm{
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.996);
  padding: 50px 70px !important;
  overflow: hidden;
}
.model_box{
    flex: 1;
    margin-top: 30px;
    .describe{
        font-family: '幼圆', sans-serif;
        font-size: 17px;
        color: #797979;
        margin-top: 40px;
        width: 67%;
        line-height: 26px;
    }
}
.icon_box{
    flex: 1;
    margin-top: 40px;
    .title{
        font-family: '幼圆', sans-serif;
        font-size: 17px;
        color: #797979;
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
}
</style>